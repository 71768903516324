import StepperComp from "../../Utils/Stepper"
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import blocation from "../../Assets/blocation.png"
import { useContext, useEffect, useState } from "react";
import UserContext from "../UserContext";
import Delete from "../../Assets/delete.svg"
import notes from "../../Assets/Notes.png"
import Forward from "../../Assets/Forward.png"
import PriceCard from "../../Utils/PriceCard";
import NavButton from "../../Utils/SignButton";
import TotalPrice from "../TotalPrice";
import Cancellations from "../../Assets/Cancellations.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import smoothScrolltoTop from "../../Utils/Scroll";
const PriceSummary=()=>
{
    const storedata=useSelector((storedata)=>storedata)
    const { userData } = useSelector((state) => state.userReducer);
    const property_name=localStorage.getItem("property_name")
    const location=localStorage.getItem("location")
    // const{property_name,per_night_inr,location}=storedata?.propertyreducer?.propertydetails
    // console.log(storedata?.propertyreducer?.propertydetails)
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        if (shouldRedirect && userData && userData.mobile_number) {
            navigate("/payment");
            smoothScrolltoTop();
            setShouldRedirect(false);
        }
    }, [userData, shouldRedirect, navigate]);


    const steps = [
        {"name":'Select rooms',"icon":RadioButtonUncheckedIcon},
        {"name":'Summary & services',"icon":RadioButtonUncheckedIcon},
        {"name":'Contact details',"icon":RadioButtonUncheckedIcon},
        {"name":'Complete',"icon":RadioButtonUncheckedIcon}
      ]
      const{date,count}=useContext(UserContext)
      const price=localStorage.getItem("totalprice")
      let checkindate=new Date(date.checkin)
      let checkoutdate=new Date(date.checkout)
      const diff=checkoutdate-checkindate
      const differenceInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
      const tax=Math.ceil(price*0.09)
      const totalprice=price+tax*2
 
    const { openlogin, setopenlogin } = useContext(UserContext)
    const [open,setopen]=useState(false)
  
    
    return(
        <div style={{fontFamily:"Inter"}} className="sm:px-[13px] sm:py-[20px] lxl:px-[100px] lg:pr-[20px] lg:pl-[40px] lg:pt-[60px] lg:pb-[100px]">
            <StepperComp steps={steps} activeStep={1} />
            <p style={{fontFamily:"Playfair Display"}} className="sm:mt-[25px] lg:mt-[65px] sm:mb-[20px] lg:mb-[30px] font-[600] sm:text-[26px] lg:text-[32px] sm:leading-[35px] lg:leading-[43px] text-[#0C0C0C]">Price summary & additional service</p>
            <div className="flex lg:flex-row sm:flex-col gap-[50px]">
                <div className="flex flex-col w-full gap-[20px]">
                    <div className="bg-white sm:p-[20px] lg:px-[40px] lg:pt-[40px] pb-[25px]">
                        <p className="font-[600] text-[20px] leading-[24.2px] mb-[12px]">{property_name}</p>
                        <div className="flex items-center gap-[3px] mb-[20px]">
                            <img src={blocation}/>
                            <p className="font-[400] sm:text-[13px] lg:text-[16px] leading-[20px] text-[#8F8F8F]">{location}</p>
                        </div>
                        <div className="flex lg:flex-row sm:flex-col items-center bg-[#F1F1F1] sm:px-[20px] lg:px-[30px] lg:py-[17px] sm:py-[15px] sm:gap-[15px] lg:gap-[100px]">
                            <div className="w-full flex flex-col gap-[4px]">
                                <p style={{fontFamily:"Proxima Nova"}} className="text-[14px] font-[600] text-[#C69130] leading-[17px] tracking-[0.35px]">Check-in date</p>
                                <div className="text-[14px] text-center font-[600] leading-[28px] tracking-[0.35px] bg-white px-[20px] py-[14px]">
                                    {date.checkin}
                                </div>
                            </div>
                            <div className="w-[50%] text-center bg-[#FFF0C5] px-[11px] py-[5px] text-[14px] lg:mt-[12px] font-[600] leading-[17px]">
                                {differenceInDays} {differenceInDays==1?"night":"nights"} 
                            </div>
                            <div className=" w-full flex flex-col gap-[4px]">
                                <p style={{fontFamily:"Proxima Nova"}} className="text-[14px] font-[600] text-[#C69130] leading-[17px] tracking-[0.35px]">Check-out date</p>
                                <div className="text-[14px] text-center  font-[600] leading-[28px] tracking-[0.35px] bg-white px-[20px] py-[14px]">
                                    {date.checkout}
                                </div>
                            </div>
                        </div>
                        <div style={{fontFamily:"Inter"}} className="flex justify-between items-center mt-[25px]">
                            <p className="font-[600] text-[#0C0C0C] text-[16px] leading-[19px]">{count.adults} {count.adults==1?"Adult":"Adults"}, {count.children} {count.children==1?"Child":"Children"}</p>
                            <div className="flex sm:gap-[12px] lg:gap-[18px] justify-between items-center">
                                <p className="text-[16px] font-[500] leading-[19px] text-[#8F8F8F]">₹{price}</p>
                                {/* <img className="cursor-pointer" src={Delete} /> */}
                            </div>
                        </div>
                    </div>

                    <div style={{fontFamily:"Inter"}} className="flex justify-between items-start cursor-pointer bg-white sm:px-[15px] sm:py-[20px] lg:pl-[43px] lg:pr-[63px] lg:py-[23px]">
                        <div className="flex items-start gap-[10px]">
                            <img src={Cancellations} />
                            <div className="flex flex-col gap-[15px]">
                                <p className="font-[700] sm:text-[14px] lg:text-[16px] text-[#565656] leading-[18px] tracking-[3%]" >Cancellation Policy</p>
                                <p className="text-black font-[400] sm:text-[13px] lg:text-[14px] leading-[18px] tracking-[0.42px]">Free cancellation is available till 5 hours before the check in <span className="font-[600] italic cursor-pointer"><span onClick={()=>setopen(!open)}>{open?"read less":"read more"}</span></span></p>
                                <p className="text-[12px] leading-[25px]" style={{"display":open?"block":"none",fontFamily:"Poppins"}}>
                                <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                                    <li style={{ marginLeft: '20px' }}>
                                    <strong>(i)</strong> Before 30 days (about 4 and a half weeks) of check-in – No Deduction of room nights/tariff<br/>
                                    Full refund for cancellation up to 30 days (about 4 and a half weeks) before check-in. If booked fewer than 30 days (about 4 and a half weeks) before check-in, full refund for cancellation made within 48 hours (about 2 days) of booking.
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                    <strong>(ii)</strong> Before 21 days (about 3 weeks) of check-in – 25% Deduction of room nights/tariff<br/>
                                    If booking is canceled before 21 days (about 3 weeks), then a 25% deduction is applicable from the Total booking amount.
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                    <strong>(iii)</strong> Before 14 days (about 2 weeks) of check-in – 50% Deduction of room nights/tariff<br/>
                                    If booking is canceled before 14 days, then a 50% deduction is applicable from the Total booking amount.
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                    <strong>(iv)</strong> Before 7 days or later of check-in – 100% Deduction of room nights/tariff<br/>
                                    If booking is canceled before a week or less, then no refund is applicable from the Total booking amount.
                                    </li>
                                </ul>
                                </p>


                            </div>
                        </div>
                        {/* <img src={Forward} /> */}
                    </div>

                    {/* <div style={{fontFamily:"Inter"}} className="flex justify-between items-start cursor-pointer bg-white sm:px-[15px] sm:py-[20px] lg:pl-[43px] lg:pr-[63px] lg:py-[23px]">
                        <div className="flex items-center gap-[10px]">
                            <img src={notes} />
                            <a href={localStorage.getItem("houserules")} target="blank" className="font-[700] sm:text-[14px] lg:text-[16px] leading-[18px] tracking-[3%] text-[#565656]" >Property policies & rules</a>
                        </div>
                        <img src={Forward} />
                    </div> */}
                </div>
                <div style={{fontFamily:"Inter"}} className="sm:w-full lg:w-[29%] flex flex-col gap-[20px]">
                    <TotalPrice showCouponCard />
                    <NavButton id="activeeffect" onClick={()=>
                    {
                        // if (!storedata?.userReducer?.token) {
                        //     setopenlogin(!openlogin)
                        // } else {
                            if (!userData || !userData.mobile_number) {
                                setopenlogin(!openlogin);
                                setShouldRedirect(true);

                            } else {
                                navigate("/payment");
                                smoothScrolltoTop();
                            }
                            // window.scrollTo({
                            //     top: 0,
                            //     behavior: 'smooth'
                            // });
                            // smoothScrolltoTop()
                        // }
                    }} name="BOOK NOW" classname="w-full mt-[20px] bg-[#C69130] py-[15px] text-white text-[16px] font-[700] leading-[19px] tracking-[0.8px] hover:bg-[#976E23]" />
                </div>
            </div>
        </div>
    )
}
export default PriceSummary