import StepperComp from "../../Utils/Stepper";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import React, {   useEffect } from 'react';


import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  useFormik,
  useFormikContext,
} from "formik";
import Visa from "../../Assets/Visa.png";
import MasterCard from "../../Assets/MasterCard.png";
import Paypal from "../../Assets/Paypal.png";
import * as yup from "yup";
import TotalPrice from "../TotalPrice";
import NavButton from "../../Utils/SignButton";
import { toast } from "react-toastify";
import { cashfreeSandbox, cashfreeProd } from "cashfree-dropjs";
import { useContext, useRef, useState } from "react";
import { Box, debounce, IconButton, Modal } from "@mui/material";
// import { Close } from "@material-ui/icons";
import { addBooking, GetUserDetails } from "../../Redux/Actions/api";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../UserContext";
import dateFormat from "dateformat";
import { useDispatch, useSelector } from "react-redux";
import useRazorpay from "react-razorpay";
import payment_logo from "../../Assets/Logo.png";

const schema = yup.object().shape({
  phone: yup
    .string()
    .matches(/^\d+$/, "Phone number must only contain digits")
    .max(10, "Phone number must be of 10 digits")
    .min(10, "Phone number must be of 10 digits")
    .required("Phone Number is required"),

  name: yup
    .string()
    .matches(/^[a-z ,.'-]+$/i, "Name must not contain special characters")
    .required("Name is required"),

  email: yup
    .string()
    .required("Email is required")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email"),

  country: yup.string().required("Country is required"),

  address: yup.string().required("Address is required"),
});

const Summary = () => {
  const [isUserDataAvailable, setIsUserDataAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const steps = [
    { name: "Select rooms", icon: RadioButtonUncheckedIcon },
    { name: "Summary & services", icon: RadioButtonUncheckedIcon },
    { name: "Contact details", icon: RadioButtonUncheckedIcon },
    { name: "Complete", icon: RadioButtonUncheckedIcon },
  ];
  let toastId = null
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    country: "",
    address: "",
  };
  const [pay, setPay] = useState(false);
  const navigate = useNavigate();
  const { date, count } = useContext(UserContext);
  const { userData } = useSelector((state) => state.userReducer);
  const formRef = useRef(null);
  const terms = useRef(false);
  const dispatch = useDispatch();
  const notes = useRef("");
  const Razorpay = useRazorpay();
  const { openlogin, setopenlogin } = useContext(UserContext);
  const [components, setComponents] = useState([
    "order-details",
    "card",
    "upi",
    "app",
    "netbanking",
    "paylater",
    "credicardemi",
  ]);

  const handleAutofill = async (isChecked) => {
    if (!isChecked) {
      // If unchecked, clear the form
      if (formRef.current) {
        formRef.current.setValues({
          name: "",
          phone: "",
          email: "",
          country: "",
          address: "",
        });
      }
      return;
    }
  
    if (!isUserDataAvailable) {
      try {
        // Assuming you have a function to fetch user details
        const userDetails = await GetUserDetails();
        // Update your Redux store with this data
        // dispatch(updateUserData(userDetails));
      } catch (error) {
        toast.error("Couldn't fetch user details. Please try again.");
        return;
      }
    }
  
    if (formRef.current && userData) {
      formRef.current.setValues({
        name: userData.name || "",
        phone: userData.mobile_number || "",
        email: userData.email || "",
        country: userData.country || "",
        address: userData.address || "",
      });
    }
  };
  useEffect(() => {
    if (userData && userData.mobile_number) {
      setIsUserDataAvailable(true);
    }
  }, [userData]);
  const handlesubmit = debounce(() => {
    console.log("values:", formRef.current.values, notes.current);
    let formData = formRef.current.values;
    let booking_detail = JSON.parse(localStorage.getItem("booking_detail"));
    let coupon_value = JSON.parse(localStorage.getItem("discount"));
    let coupon_code = localStorage.getItem("coupon");

    if (!date || !date.checkin || !date.checkout) {
      toast.error();
      if (toastId) {
        toast.update(toastId, { render: "Dates not found.", type: toast.TYPE.ERROR });
    } else {
        toastId = toast.error("Dates not found.", {
            onClose: () => (toastId = null) // Reset toastId when toast is closed
        });
    }
      console.log("Dates:1");
    } else if (!userData || !userData.mobile_number) {
      setopenlogin(!openlogin);
    } else if (
      !booking_detail ||
      !booking_detail.brik_id ||
      !booking_detail.spv_id
    ) {
      toast.error("Something went wrong.", { variant: "error" });
      
    } else if (
      !formData ||
      formData.name.length <= 0 ||
      formData.email.length < 10 ||
      formData.phone.length < 10
    ) {
    
      if (toastId) {
        toast.update(toastId, { render: "Guest details required.", type: toast.TYPE.ERROR });
    } else {
        toastId = toast.error("Guest details required.", {
            onClose: () => (toastId = null) // Reset toastId when toast is closed
        });
    }
      
    } else if (!terms.current) {
      
      if (toastId) {
        toast.update(toastId, { render: "Please accept Terms of service and privacy statement", type: toast.TYPE.ERROR });
    } else {
        toastId = toast.error("Please accept Terms of service and privacy statement", {
            onClose: () => (toastId = null) // Reset toastId when toast is closed
        });
    }
    } else {
      console.log("Dates:3");

      addBooking({
        brik_id: booking_detail.brik_id,
        spv_id: booking_detail.spv_id,
        user: userData.mobile_number,
        coupon_value: coupon_value,
        coupon_code: coupon_code,
        date_check_in: dateFormat(date.checkin, "yyyymmdd"),
        date_check_out: dateFormat(date.checkout, "yyyymmdd"),
        adult: count.adults,
        children: count.children,
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        instructions: notes.current,
      })
        .then((result) => {
          console.log("payment details", result);
          renderRazorpayGetway(result.data);
        })
        .catch((e) => {
         

          if (toastId) {
            toast.update(toastId, { render: e.response?.data?.detail
                ? e.response.data.detail
                : "Something went wrong.", type: toast.TYPE.ERROR });
        } else {
            toastId = toast.error(e.response?.data?.detail
                ? e.response.data.detail
                : "Something went wrong.", {
                onClose: () => (toastId = null) // Reset toastId when toast is closed
            });
        }
        });
    }
  }, 900);
  const handleClick = () => {
    console.log("values:", formRef.current.values, notes.current);
    let formData = formRef.current.values;
    let booking_detail = JSON.parse(localStorage.getItem("booking_detail"));
    let coupon_value = JSON.parse(localStorage.getItem("discount"));
    let coupon_code = localStorage.getItem("coupon");
    if (!date || !date.checkin || !date.checkout) {
      toast.error("Dates not found.");
      console.log("Dates:1");
    } else if (!userData || !userData.mobile_number) {
      // toast.error('Login required.')
      // console.log('Dates:2')

      setopenlogin(!openlogin);
    } else if (
      !booking_detail ||
      !booking_detail.brik_id ||
      !booking_detail.spv_id
    ) {
      toast.error("Something went wrong.", {
        variant: "error",
      });
    } else if (
      !formData ||
      formData.name.length <= 0 ||
      formData.email.length < 10 ||
      formData.phone.length < 10
    ) {
      toast.error("Guest details required.", {
        variant: "error",
      });
    } else if (!terms.current) {
      toast.error("Please accept Terms of service and privacy statement", {
        variant: "error",
      });
    } else {
      console.log("Dates:3");
      // setPay(true);
      addBooking({
        brik_id: booking_detail.brik_id,
        spv_id: booking_detail.spv_id,
        user: userData.mobile_number,
        coupon_value: coupon_value,
        coupon_code: coupon_code,
        date_check_in: dateFormat(date.checkin, "yyyymmdd"),
        date_check_out: dateFormat(date.checkout, "yyyymmdd"),
        adult: count.adults,
        children: count.children,
        name: formRef.current.values.name,
        phone: formRef.current.values.phone,
        email: formRef.current.values.email,
        instructions: notes.current,
      })
        .then((result) => {
          console.log("payment details", result);
          // setPay(true);
          // let token = result.data.order_token;
          // renderDropin(token);
          renderCCAvenueGateway(result.data);
        })
        .catch((e) => {
          toast.error(
            e.response?.data?.detail
              ? e.response.data.detail
              : "Something went wrong.",
            {
              variant: "error",
            }
          );
          // setPay(false);
        });
    }
  };
  const renderCCAvenueGateway = async (data) => {
    const apiUrl = "https://owners.brikitt.com/api/v2/ccavRequestHandler";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        merchant_id: "3041703",
        order_id: data.notes.receipt,
        currency: "INR",
        amount: data.amount,
        redirect_url: "https://owners.brikitt.com/api/v2/ccavResponseHandler",
        cancel_url: "https://brikittrentals.brikitt.com/payment",
        language: "EN",
        billing_name: "",
        billing_address: "",
        billing_city: "",
        billing_state: "",
        billing_zip: "",
        billing_country: "",
        billing_tel: "",
        billing_email: "",
        delivery_name: "",
        delivery_address: "",
        delivery_city: "",
        delivery_state: "",
        delivery_zip: "",
        delivery_country: "",
        delivery_tel: "",
        merchant_param1: "",
        merchant_param2: "",
        merchant_param3: "",
        merchant_param4: "",
        merchant_param5: "",
        promo_code: "",
        customer_identifier: "",
      }),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const htmlContent = await response.text();

      // Open a new window
      const newWindow = window.open("", "_blank");

      // Set the document content of the new window
      newWindow.document.write(htmlContent);

      // Close the document to prevent any interference with the original page
      newWindow.document.close();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const renderDropin = (orderToken) => {
    // console.log('orderToken:', orderToken)

    if (orderToken === "") {
      alert("Order Token is empty");
      return;
    }

    let parent = document.getElementById("drop_in_container");
    parent.innerHTML = "";
    let cashfree;
    // change cashfree to cashfreeSandbox to test in local

    // if (isProd) {
    //     cashfree = new cashfreeProd.Cashfree();
    // } else {
    //     cashfree = new cashfreeSandbox.Cashfree();
    // }

    cashfree = new cashfreeProd.Cashfree();

    console.log("before Initialisation");
    cashfree.initialiseDropin(parent, {
      orderToken,
      onSuccess: cbs,
      onFailure: cbf,
      components,
    });
    console.log("after Initialisation");
  };

  const renderRazorpayGetway = (data) => {
    // console.log('data:', data)
    if (data === "") {
      alert("Order Data is empty");
      return;
    }

    var options = {
      key: "rzp_live_TtfanSJ9Eh1J6g",
      amount: data.amount,
      currency: "INR",
      name: "BRIK itt",
      description: "Booking Portal Transaction",
      image: payment_logo,
      order_id: data.id,
      receipt: data.notes.receipt,
      notes: data.notes,
      theme: {
        color: "#897860",
      },
      handler: function (response) {
        console.log("Payment success:", response);
        toast.success("Payment successful!");
        navigate("/paymentsuccess");
      },
    };
    console.log("--------------------------------");
    var rzp1 = new Razorpay(options);
    rzp1.open();

    console.log("rzp1:", rzp1);
    rzp1.on("payment.success", function (response) {
      // Display a success message here
      toast.success("Payment successful!");
      navigate("/paymentsuccess");
    });
    rzp1.on("payment.failed", function (response) {
      toast.error(response.error.description);
      // notification.enqueueSnackbar(response.error.description, {
      //     variant: "error",
      //     autoHideDuration: 2000
      // })
    });
  };

  const cbs = (data) => {
    if (data.order && data.order.status === "PAID") {
      toast.success("Transaction Successful");
    }
    navigate("/paymentsuccess");
  };
  const cbf = (data) => {
    if (data && data.order && data.order.errorText) {
      toast.error(data.order.errorText);
    } else {
      toast.error("Something went wrong!");
    }
  };

  return (
    <>
      <Modal
        className="m-auto mt-[5%] lg:w-[40%] sm:w-[90%]"
        onClose={() => setPay(false)}
        open={pay}
      >
        <Box
          style={{ height: "80%", overflow: "auto" }}
          id="drop_in_container"
        ></Box>
      </Modal>
      <div
        style={{ fontFamily: "Inter" }}
        className="sm:px-[16px] sm:py-[20px] lxl:px-[100px] lg:pr-[20px] lg:pl-[40px] lg:pt-[60px] lg:pb-[100px]"
      >
        <StepperComp steps={steps} activeStep={2} />
        <p
          style={{ fontFamily: "Playfair Display" }}
          className="sm:mt-[43px] lg:mt-[65px] sm:mb-[20px] lg:mb-[30px] font-[600] sm:text-[26px] lg:text-[32px] leading-[43px] text-[#0C0C0C]"
        >
          Guest details
        </p>
          <div className="mb-4">
    <label className="flex items-center space-x-2">
      <input
        type="checkbox"
        onChange={async (e) => {
          setIsLoading(true);
          await handleAutofill(e.target.checked);
          setIsLoading(false);
        }}
        disabled={isLoading}
      />
      <span className="sm:text-[14px] lg:text-[16px] leading-[19px] font-[400]">
        {isLoading ? "Loading..." : "Autofill Guest Details"}
      </span>
    </label>
  </div>
        <div className="flex lg:flex-row sm:flex-col gap-[50px] items-start">
          <div className="flex flex-col w-full">
            <div className="bg-white sm:px-[10px] sm:py-[16px] lg:p-[40px]">

          
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={schema}
              >
                <Form
                  style={{ fontFamily: "Inter" }}
                  className="sm:text-[14px] lg:text-[16px] leading-[20px] tracking-[3%] font-[400] flex flex-col gap-[20px]"
                >
                  <div>
                    <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px]">
                      FULL NAME<span className="text-[#FF5656]">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      name="name"
                      className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]"
                      placeholder="Enter Full Name"
                      required
                    />
                    <ErrorMessage
                      className="text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]"
                      name="name"
                      component="div"
                    />
                  </div>

                  <div>
                    <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px]">
                      MOBILE NUMBER<span className="text-[#FF5656]">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      name="phone"
                      className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]"
                      placeholder="Mobile number"
                      required
                    />
                    <ErrorMessage
                      className="text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]"
                      name="phone"
                      component="div"
                    />
                  </div>

                  <div>
                    <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px] ">
                      EMAIL ID<span className="text-[#FF5656]">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      name="email"
                      className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]"
                      placeholder="Enter Email ID"
                      required
                    />
                    <ErrorMessage
                      className="text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]"
                      name="email"
                      component="div"
                    />
                    <p className="text-[#565656] text-[12px] font-[400] leading-[15px] mt-[9px]">
                      We’ll send your booking information on your mobile &
                      email.
                    </p>
                  </div>

                  {/* <div>
                                    <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px] " >COUNTRY<span className="text-[#FF5656]">*</span></label><br/>
                                    
                                    <Field as="select" className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]" name="country">
                                        <option value="*">--Select--</option>
                                        <option value="India">India</option>
                                    </Field>
                                    <ErrorMessage className='text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]' name='country' component="div"/>
                                </div> */}

                  {/* <div>
                                    <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px]">ADDRESS<span className="text-[#FF5656]">*</span></label><br/>
                                    <Field type="text" component="textarea" name="address" className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]" placeholder="Enter Address" required/>
                                    <ErrorMessage className='text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]' name='address' component="div"/>
                                    
                                </div> */}
                </Form>
              </Formik>
            </div>
            <div className="bg-white sm:mt-[15px] lg:mt-[30px] sm:px-[10px] sm:py-[16px] lg:px-[40px] lg:py-[20px]">
              <p
                style={{ fontFamily: "Playfair Display" }}
                className="mb-[20px] font-[600] text-[22px] leading-[30px] text-[#0C0C0C]"
              >
                Notes
              </p>
              <p className="font-[600] sm:text-[12px] lg:text-[14px] leading-[17px] text-[#282827]">
                ADDITIONAL NOTES IF ANY
              </p>
              <textarea
                className="sm:text-[14px] lg:text-[16px] bg-[#F5F6FA] p-[13px] w-full mt-[7px]"
                placeholder="Enter your notes"
                onChange={(e) => (notes.current = e.target.value)}
              />
            </div>
          </div>
          <div
            style={{ fontFamily: "Inter" }}
            className="sm:w-full lg:w-[45%] flex flex-col gap-[20px]"
          >
            <TotalPrice />
            {/* <p className="font-[600] text-[22px] leading-[30px] mt-[30px] text-[#0C0C0C]">Payment method</p>
                    <div className="flex gap-[50px] items-center">
                        <img src={Visa}/>
                        <img src={MasterCard}/>
                        <img src={Paypal}/>
                    </div> */}
            <div className="flex items-start gap-[10px] mt-[15px]">
              <input
                type="checkbox"
                onChange={(e) => (terms.current = e.target.checked)}
              />
              <p className="sm:text-[14px] lg:text-[16px] leading-[19px] font-[400]">
                {/* <button onClick= {handleClick}>*</button> */}I agree with{" "}
                <Link
                  to="https://brikkit-prod-docs.s3.amazonaws.com/media/brik/terms_conditions/ownerTERMS__CONDITIONS_FOR_BRIKITT_HOLIDAY_HOME.pdf"
                  style={{ color: "blue" }}
                  target="_blank"
                >
                  Terms of service
                </Link>{" "}
                and{" "}
                <Link
                  to="https://brikkit-prod-docs.s3.amazonaws.com/media/brik/terms_conditions/ownerTERMS__CONDITIONS_FOR_BRIKITT_HOLIDAY_HOME.pdf"
                  style={{ color: "blue" }}
                  target="_blank"
                >
                  privacy statement
                </Link>
              </p>
            </div>
            {/* <select className="p-[15px] mt-[10px]">
                        <option value="Paypal">Paypal</option>
                    </select> */}
            <NavButton
              name="PAY NOW"
              classname="w-full mt-[20px] bg-[#C69130] py-[15px] text-white text-[16px] font-[700] leading-[19px] tracking-[0.8px] hover:bg-[#976E23]"
              onClick={handlesubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Summary;
